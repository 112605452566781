import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import HeaderOurWork from "../components/headers/header-our-work";
import Layout from "../components/layout/layout";
import Companies from "../components/our-work/companies";
// import Projects from "../components/global/projects";
import Seo from "../components/seo/seo";
import { graphql } from "gatsby";

const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <Seo title={t("navigation.ourWork")} />
      <HeaderOurWork />
      <Companies />
    </Layout>
  );
};

export default AboutUs;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
