import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
// import { Link } from "gatsby-plugin-react-i18next";
// import BackgroundImage from "gatsby-background-image";
import { useStaticQuery, graphql } from "gatsby";
import HeaderBcg from "../../assets/images/headers/our-work.png";

const query = graphql`
  {
    file(relativePath: { eq: "images/headers/our-work.png" }) {
      name
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const HeaderOurWork = () => {
  const { t } = useTranslation();
  const data = useStaticQuery(query);
  const imageData = data.file.childImageSharp.fluid;

  return (
    <header className="m-headerOW">
      <div fluid={imageData} className="_wr m-headerOW__wrapper">
        <div className="_w m-headerOW__box">
          <div className="_xs10 _s9 _l6">
            <div className=" m-headerOW__content">
              <h1 className="m-headerOW__content--title">
                {t("ourWork.headline")}
              </h1>
              <p className="m-headerOW__content--text">
                {t("ourWork.companies.text")}
              </p>
              {/* <Link
                className="a-btn -borderBlack a-hover -effectThree"
                to="/case-study"
              >
                {t('buttons.seeCaseStudy')}
                <span className="a-arrow -long"></span>
              </Link> */}
            </div>
          </div>
          <div
            className="m-headerMarkets__bkg -global _m5 _xl6"
            style={{ backgroundImage: `url("${HeaderBcg}")` }}
          ></div>
        </div>
      </div>
    </header>
  );
};

export default HeaderOurWork;
